import * as React from 'react'
import { graphql } from 'gatsby'
import GenericPageWithToc, {
    PageData,
} from '../components/ui/GenericPageWithToc'

type Heading = {
    id: string
    value: string
    depth: number
}

type Props = {
    data: {
        page: {
            frontmatter: {
                title: string
                slug: string
                tags: string[]
                description: string
                date: string
            }
            timeToRead: number
            html: string
            titleHeadings: Heading[]
            headings: Heading[]
            parent: {
                fields: {
                    gitLogLatestDate: string | null
                }
            }
        }
    }
}

export default function Page({ data }: Props) {
    const page: PageData = {
        ...data.page,
        frontMatter: {
            ...data.page.frontmatter,
            lastUpdated: data.page.parent.fields.gitLogLatestDate,
        },
    }
    return (
        <GenericPageWithToc
            data={{
                ...data,
                page,
            }}
        />
    )
}

export const pageQuery = graphql`
    {
        page: markdownRemark(
            fileAbsolutePath: { regex: "/res/pages/intro.md/" }
        ) {
            html
            frontmatter {
                title
                slug
            }
            timeToRead
            titleHeadings: headings(depth: h1) {
                id
                value
                depth
            }
            headings {
                id
                value
                depth
            }
            parent {
                ... on File {
                    fields {
                        gitLogLatestDate(formatString: "YYYY-MM-DD")
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
